import axios from "axios";
import Env from "@/logics/Envs.js";

export default async function (reference) {
    try {
        const toSend = new FormData();
        toSend.append("reference", reference);

        const res = await axios.post(Env.apiPath + "checkOnlineDone", toSend);
        return res.data;
    } catch (err) {
        return err;
    }
}