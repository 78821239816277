export default {
    en: {
        confirm: "Confirm",
        cancel: "Cancel",
        title: "Make Payment",
        messagePart1: "Are you sure to pay",
        messagePart2: "with the plan of",
        messagePart3: "?",
        afterConfirm: "After confirm please contact administrator for actual payment"
    },
    zh: {
        confirm: "确认",
        cancel: "取消",
        title: "支付费用",
        messagePart1: "您确定要为",
        messagePart2: "支付",
        messagePart3: "费用吗？",
        afterConfirm: "点击确认后请线下实际支付"
    }
}