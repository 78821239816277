import axios from "axios";
import Env from "@/logics/Envs.js";

export default async function (classId, planIndex, userInput, userCost) {
    try {
        const toSend = new FormData();
        toSend.append("classId", classId);
        toSend.append("planIndex", planIndex);
        if (userInput) {
            toSend.append("userInput", userInput);
        }
        if (userCost) {
            toSend.append("userCost", userCost);
        }

        const res = await axios.post(Env.apiPath + "checkoutOffline", toSend);
        return res.data.error;
    } catch (err) {
        return err;
    }
}