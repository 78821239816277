<template>
<van-dialog v-model:show="show" :title="lang[langCode].title" show-cancel-button @confirm="openWindowFirst()" :confirm-button-color="'#ee0a24'" :confir-button-disabled="!canConfirm" :confirm-button-text="confirmButtonText" :cancel-button-text="lang[langCode].cancel">
    <van-divider />
    <div class="overflow-y-scroll my-max-h px-4 pb-8 text-center">
        <div>
            <span>{{lang[langCode].messagePart1}} [</span>
            <span class="text-blue-500">{{className}}</span>
            <span>] {{lang[langCode].messagePart2}} [</span>
            <span class="text-blue-500">{{planData.action}}</span>
            <span>] {{lang[langCode].messagePart3}}</span>
        </div>
        <div class="py-4 text-center text-xs">
            {{lang[langCode].beforeJump}}
        </div>
    </div>
</van-dialog>
</template>

<script>
import lang from "@/langs/PayFeeOnlineLang.js";
import {
    ref
} from "vue";
import {
    Dialog,
    Divider,
    Notify
} from "vant";
import CheckoutOnline from "@/asyncs/CheckoutOnline.js";

export default {
    components: {
        [Dialog.Component.name]: Dialog.Component,
        [Divider.name]: Divider
    },
    props: {
        langCode: {
            type: String,
            default: "zh"
        }
    },
    setup(props, {
        emit
    }) {
        const show = ref(false);
        const planData = ref({});
        const costValue = ref(0);
        const className = ref("");
        const confirmButtonText = ref(lang[props.langCode].confirm);
        const canConfirm = ref(true);
        let planIndex = 0;
        let link = null;
        let reference = "";

        const showDialog = async function (classN, plans, planInd, userId, classId) {
            planData.value = plans[planInd];
            className.value = classN;
            costValue.value = planData.value.cost;
            show.value = true;
            planIndex = planInd;
            reference = userId + "_" + classId;
            link = planData.value.link + "?client_reference_id=" + reference;
        };

        const openWindowFirst = function () {
            window.open(link, "_blank");
            confirm();
        };

        const confirm = async function () {
            confirmButtonText.value = lang[props.langCode].wait;
            canConfirm.value = false;

            const checkoutOnlineError = await CheckoutOnline(reference.split("_")[1], planIndex);
            if (checkoutOnlineError) {
                Notify({
                    type: "danger",
                    message: checkoutOnlineError
                });
                return;
            }

            emit("confirmPay", reference);
        };

        return {
            lang,
            show,
            className,
            planData,
            costValue,
            showDialog,
            confirmButtonText,
            canConfirm,
            openWindowFirst
        };
    }
}
</script>

<style scoped>
.my-max-h {
    max-height: 60vh;
    scrollbar-width: none;
}

.my-max-h::-webkit-scrollbar {
    width: 0px;
}

.my-max-h::-webkit-scrollbar-track {
    display: none;
}
</style>
