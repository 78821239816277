import axios from "axios";
import Env from "@/logics/Envs.js";

export default async function (classId, planIndex) {
    try {
        const toSend = new FormData();
        toSend.append("classId", classId);
        toSend.append("planIndex", planIndex);

        const res = await axios.post(Env.apiPath + "checkoutOnline", toSend);
        return res.data.error;
    } catch (err) {
        return err;
    }
}
