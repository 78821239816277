export default {
    en: {
        confirm: "Confirm",
        cancel: "Cancel",
        titleCode: "Enter Discount Code",
        messageLoading: "During the payment progress, please make sure your browser DOES NOT block the pop-up window, and please DO NOT close this page, if anything goes wrong please REFRESH this page...",
        successPay: "Payment Success",
        successPayMessage: "You have successfully paid for",
        failPay: "Payment Failed",
        failCheck: "Payment went through, but the system cannot confirm your credit, please do not pay again, and contact the administrator",
        failLink: "Link Expired",
        failCode: "Invalid Code"
    },
    zh: {
        confirm: "确认",
        cancel: "取消",
        titleCode: "输入折扣访问码",
        messageLoading: "您在安全支付平台操作过程中，请确保您的浏览器不会拦截弹出窗口，请勿关闭此页，如支付不成功请刷新此页并尝试重新支付...",
        successPay: "支付成功",
        successPayMessage: "您已成功支付",
        failPay: "支付失败",
        failCheck: "支付已成功，但系统检查失败，请勿重复支付，尽快联系管理员",
        failLink: "支付链接已过期",
        failCode: "无效的折扣访问码"
    }
}