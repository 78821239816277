import axios from "axios";
import Env from "@/logics/Envs.js";

export default async function (classId) {
    try {
        const params = { classId };
        const res = await axios.get(Env.apiPath + "checkTransaction", { params });
        return [res.data.yes, res.data.error];
    } catch (err) {
        return [null, err];
    }
}