<template>
    <van-action-sheet v-model:show="show" :actions="actionList" :cancel-text="lang[langCode].cancel"
        @select="onAction" />
    <pay-online ref="payRef" @confirm-pay="confirmPayOnline" :local-code="langCode" />
    <pay-offline ref="payRefOff" @confirm-pay="confirmPayOffline" @confirm-pay-user-input="confirmPayUserInput"
        :local-code="langCode" />
    <van-dialog v-model:show="showCodeDialog" :title="lang[langCode].titleCode" show-cancel-button
        @confirm="enterCode()" :confirm-button-color="'#ee0a24'" :confirm-button-text="lang[langCode].confirm"
        :cancel-button-text="lang[langCode].cancel">
        <div class="text-center">
            <input class="border border-purple-500 w-10/12 p-1 my-4" type="text" v-model="codeIn"
                @keyup.enter="enterCode()" />
        </div>
    </van-dialog>
</template>

<script>
import lang from "@/langs/PayActionSheetLang.js";
import {
    ref
} from "vue";
import {
    useStore
} from "vuex";
import {
    ActionSheet,
    Toast,
    Dialog,
    Notify
} from "vant";
import PayOnline from "@/components/dialog/PayFeeOnline.vue";
import PayOffline from "@/components/dialog/PayFeeOffline.vue";
import CheckoutOffline from "@/asyncs/CheckoutOffline.js";
import CheckoutDone from "@/asyncs/CheckoutDone.js";

export default {
    components: {
        [ActionSheet.name]: ActionSheet,
        [Dialog.Component.name]: Dialog.Component,
        PayOnline,
        PayOffline
    },
    props: {
        langCode: {
            type: String,
            default: "zh"
        }
    },
    emits: ["paymentSuccess"],
    setup(props, {
        emit
    }) {
        Toast.setDefaultOptions({
            className: "break-normal"
        });
        const store = useStore();
        const show = ref(false);
        const actionList = ref([]);
        const payRef = ref(null);
        const payRefOff = ref(null);
        const showCodeDialog = ref(false);
        const codeIn = ref("");
        let userIdLocal = null;

        let classDetails = null;
        let keepLiveOnce = false;
        const pushActionToList = function (plan, index, classDetails, color) {
            actionList.value.push({
                name: plan.action,
                subname: plan.info,
                color: color,
                action: function () {
                    if (plan.offline) {
                        payRefOff.value.showDialog(classDetails.className, classDetails.payPlans, index);
                    } else {
                        payRef.value.showDialog(classDetails.className, classDetails.payPlans, index, userIdLocal, classDetails.classId);
                    }
                }
            });
        };
        const showActions = function (c, userId) {
            userIdLocal = userId;
            actionList.value.splice(0, actionList.value.length);
            classDetails = c;
            keepLiveOnce = false;
            let hasCode = false;
            classDetails.payPlans.forEach(function (plan, index) {
                if (plan.code) {
                    hasCode = true;
                    return;
                }
                pushActionToList(plan, index, classDetails);
            });
            if (hasCode) {
                actionList.value.push({
                    name: lang[props.langCode].titleCode,
                    action: function () {
                        keepLiveOnce = true;
                        showCodeDialog.value = true;
                    }
                });
            }
            show.value = true;
        };

        const confirmPayOnline = async function (reference) {
            const waitingToast = Toast.loading({
                message: lang[props.langCode].messageLoading,
                forbidClick: true,
                duration: 0
            });

            const check = setInterval(async function () {
                const checkOnlineDone = await CheckoutDone(reference);
                if (checkOnlineDone.error) {
                    console.log("waiting");
                    return;
                }

                clearInterval(check);
                waitingToast.clear();

                Notify({
                    type: "success",
                    message: lang[props.langCode].successPay
                });
                if (checkOnlineDone.credit) {
                    store.state.user.credit = checkOnlineDone.credit;
                    store.state.user.max = checkOnlineDone.credit;
                }
                emit("paymentSuccess");
            }, 8000);
        };

        const onAction = function (action) {
            action.action();
            if (keepLiveOnce) {
                keepLiveOnce = false;
            } else {
                show.value = false;
            }
        };

        const enterCode = function () {
            if (classDetails) {
                let foundCode = false;
                classDetails.payPlans.forEach(function (plan, index) {
                    if (plan.code) {
                        if (plan.code === codeIn.value) {
                            pushActionToList(plan, index, classDetails, "#4c86f7");
                            foundCode = true;
                        }
                    }
                });
                if (!foundCode) {
                    Notify({
                        type: "danger",
                        message: lang[props.langCode].failCode
                    });
                }
            }
            showCodeDialog.value = false;
        };

        const confirmPayOffline = async function (index) {
            const plan = classDetails.payPlans[index];
            const error = await CheckoutOffline(classDetails.classId, index);
            if (error) {
                Notify({
                    type: "danger",
                    message: lang[props.langCode].failPay + ": " + error
                });
            } else {
                Notify({
                    type: "success",
                    message: lang[props.langCode].successPay
                });
                if (plan.credit) {
                    store.state.user.credit = plan.credit;
                    store.state.user.max = plan.credit;
                }
                emit("paymentSuccess");
            }
        };

        const confirmPayUserInput = async function ({
            index,
            input,
            cost
        }) {
            const error = await CheckoutOffline(classDetails.classId, index, input, cost);
            if (error) {
                Notify({
                    type: "danger",
                    message: lang[props.langCode].failPay + ": " + error
                });
            } else {
                Notify({
                    type: "success",
                    message: lang[props.langCode].successPay
                });
                emit("paymentSuccess");
            }
        };

        return {
            lang,
            show,
            actionList,
            showActions,
            onAction,
            payRef,
            payRefOff,
            confirmPayOnline,
            confirmPayOffline,
            confirmPayUserInput,
            showCodeDialog,
            enterCode,
            codeIn
        };
    }
}
</script>
