export default {
    en: {
        confirm: "Confirm",
        cancel: "Cancel",
        title: "Make Payment",
        beforeJump: "Click 'Confirm' to go to the secure payment platform, meanwhile please do not close this page, and please make sure your browser doesn't block the pop-up window",
        messagePart1: "Are you sure to pay",
        messagePart2: "with the plan of",
        messagePart3: "?",
        wait: "Please wait...",
        failLink: "Invalid Link"
    },
    zh: {
        confirm: "确认",
        cancel: "取消",
        title: "支付费用",
        beforeJump: "点击确认后会跳转到安全的第三方支付平台，请勿关闭此页，并且请确保您的浏览器不会拦截弹出窗口",
        messagePart1: "您确定要为",
        messagePart2: "支付",
        messagePart3: "费用吗？",
        wait: "请稍等...",
        failLink: "链接失败"
    }
}