<template>
<van-dialog v-model:show="show" :title="lang[langCode].title" show-cancel-button @confirm="confirm()" :confirm-button-color="'#ee0a24'" :confirm-button-text="lang[langCode].confirm" :cancel-button-text="lang[langCode].cancel">
    <van-divider />
    <div class="overflow-y-scroll my-max-h px-4 pb-8 text-center select-none">
        <div>
            <span>{{lang[langCode].messagePart1}} [</span>
            <span class="text-blue-500">{{className}}</span>
            <span>] {{lang[langCode].messagePart2}} [</span>
            <span class="text-blue-500">{{planData.action}}</span>
            <span>] {{lang[langCode].messagePart3}}</span>
        </div>
        <div v-if="planData.input" class="py-8 text-lg text-red-500">{{costText}}</div>
        <div v-if="!planData.input" class="py-8 text-lg text-red-500">${{planData.cost}}</div>
        <van-slider v-if="planData.input" v-model="userValue" :min="planData.input.min" :max="planData.input.max" @update:model-value="updateCost" />
        <div class="py-4 text-center text-xs">
            {{lang[langCode].afterConfirm}}
        </div>
    </div>
</van-dialog>
</template>

<script>
import lang from "@/langs/PayFeeOfflineLang.js";
import {
    ref
} from "vue";
import {
    Dialog,
    Divider,
    Slider
} from "vant";

export default {
    components: {
        [Dialog.Component.name]: Dialog.Component,
        [Divider.name]: Divider,
        [Slider.name]: Slider
    },
    props: {
        langCode: {
            type: String,
            default: "zh"
        }
    },
    setup(props, {
        emit
    }) {
        const show = ref(false);
        const planData = ref({});
        const className = ref("");
        let planIndex = 0;
        const costText = ref("");
        const userValue = ref(0);
        let masks = null;

        const showDialog = function (classN, plans, planIn) {
            planData.value = plans[planIn];
            className.value = classN;
            show.value = true;
            planIndex = planIn;

            masks = [];
            if (planData.value.input) {
                planData.value.input.mask.split(";").forEach(function (m) {
                    const parts = m.split(",");
                    const mask = {};
                    if (parts[0]) {
                        mask.product = parseFloat(parts[0].trim());
                    }
                    if (parts[1]) {
                        mask.additional = parseFloat(parts[1].trim());
                    }
                    masks.push(mask);
                });
                userValue.value = planData.value.input.min;
                updateCost();
            }
        };

        const updateCost = function () {
            costText.value = planData.value.input.template;
            masks.forEach(function (mask, index) {
                let convertValue = userValue.value;
                if (mask.product) {
                    convertValue = convertValue * mask.product;
                }
                if (mask.additional) {
                    convertValue = convertValue + mask.additional;
                }
                costText.value = costText.value.replace("{" + index + "}", convertValue);
                if (index === 0) {
                    planData.value.cost = convertValue;
                }
            });
        };

        const confirm = function () {
            if (userValue.value > 0) {
                emit("confirmPayUserInput", {
                    index: planIndex,
                    input: costText.value,
                    cost: planData.value.cost
                });
            } else {
                emit("confirmPay", planIndex);
            }

        };

        return {
            lang,
            show,
            className,
            planData,
            showDialog,
            confirm,
            costText,
            updateCost,
            userValue
        };
    }
}
</script>

<style scoped>
.my-max-h {
    max-height: 60vh;
    scrollbar-width: none;
}

.my-max-h::-webkit-scrollbar {
    width: 0px;
}

.my-max-h::-webkit-scrollbar-track {
    display: none;
}
</style>
